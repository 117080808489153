import React, { useEffect } from 'react';

import Hero from '../Hero'; // Dont Comment out.. Contains the link to styling. 
import Hero5 from '../Hero/Hero5';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
// import VideoModal from '../VideoModal';
import { pageTitle } from '../../helper';
import FunFact2 from '../FunFact/FunFact2';
import PortfolioSlider2 from '../Slider/PortfolioSlider2';
import ServiceList from '../ServiceList';
import ReactPlayer from 'react-player';
// import VideoModal2 from '../VideoModal';
// import Card from '../Card';
// import FunFact from '../FunFact';
// import PortfolioSlider from '../Slider/PortfolioSlider';
// import TeamSlider from '../Slider/TeamSlider';
// import TimelineSlider from '../Slider/TimelineSlider';
// import PostSlider from '../Slider/PostSlider';
// import SectionHeading from '../SectionHeading';
// import TestimonialSlider from '../Slider/TestimonialSlider';
// import PricingTableList from '../PricingTable/PricingTableList';
// import InfinitySymbol from '../InfinityLoop/Infinity';

export default function MarketingAgencyHome() {
  pageTitle('Home');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: 'Medium',
      links: 'https://medium.com/@chainpeak',
    },
    {
      name: 'Twitter',
      links: 'https://twitter.com/chainpeak',
    },
  ];
  const funfaceData = [
    {
      title: 'Global user reached',
      factNumber: '5 million',
    },
    {
      title: 'Happy clients',
      factNumber: '120',
    },
    {
      title: 'Team members',
      factNumber: '60',
    },
    {
      title: 'Project completed',
      factNumber: '120',
    },
  ];
  return (
    <>
      {/* Start Hero Section */}
      <Hero5

        title="Unleash your project's potential with <br/> <span style='background: #2F2796;
              background: linear-gradient(to right, #2F2796 26%, #23D5AB 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;'>ChainPeak</span>"
        // title="Unleash your project's potential with <br/> ChainPeak" 
        subtitle="Empowering Growth and Development for Promising Cryptocurrency Projects"
        btnLink="contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
      />
      {/* End Hero Section */}




      {/* Start Video Block Section */}

      {/* <Div className="cs-video_block_1_wrap">
        <Div className="container">
          <VideoModal2
            videoSrc="https://www.youtube.com/watch?v=bOZS1CrOzgQ"
=======
      <Div className="cs-video_block_1_wrap">
        <Div className="container ">
          <VideoModal
            videoSrc="https://youtu.be/bOZS1CrOzgQ"
            bgUrl="/images/video_bg_2.jpeg"

          />
        </Div>
      </Div> */}
      {/* End Video Block Section */}

      {/* Start Video Block Section */}
      {/* <Div className="cs-video_block_1_wrap">
        <Div className="container">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=bOZS1CrOzgQ"
            config={{youtube: { playerVars: { disablekb:1 } }}}
            playing={true}
            muted={true} 
            controls={false}
            loop={true}
            // volume={1}
          />
        </Div>
      </Div> */}





      {/* Start Video Block Section */}
      <Div className="container" style={{ borderRadius: 20, pointerEvents: 'none', marginTop: '-70px', overflow: 'hidden' }}>
        <div style={{ position: 'relative', paddingBottom: '56.25%', overflow: 'hidden', borderRadius: 20 }}>
          <ReactPlayer
            url={"https://www.youtube.com/watch?v=bOZS1CrOzgQ"}
            playing={true}
            loop={true}
            muted={true}
            controls={false}
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        </div>
      </Div>
      {/* End Video Block Section */}



      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our Core Services"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div>
      {/* End Services Section */}

      {/* Start PortfolioSlider Section */}
      <Spacing lg="120" md="50" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4">
          Revolutionize Your Crypto Journey with ChainPeak: Empowering Growth and Development for Promising Cryptocurrency Projects

        </h2>
      </Div>
      <Spacing lg="90" md="70" />
      <PortfolioSlider2 />
      {/* End PortfolioSlider Section */}

      {/* Start FunFact Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <FunFact2
          data={funfaceData}
          variant="cs-no_shadow"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div>
      {/* End FunFact Section */}

      {/* Start Pricing Section */}
      {/* <Spacing lg="145" md="80" /> */}
      {/* <Div className="container">
        <SectionHeading
        title="Providing best <br/>pricing for client"
        subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />
        <PricingTableList />
      </Div> */}
      {/* <Spacing lg="125" md="55" /> */}
      {/* End Pricing Section */}
      {/* Start Infinity Loop Section */}
      {/* <Spacing lg="145" md="80" /> */}
      {/* <Div className="container"> */}
      {/* implement infinity.jsx from InfinityLoop */}
      {/* <InfinitySymbol /> */}
      {/* </Div> */}



      <Spacing lg="125" md="55" />
      {/* End Infinity Loop Section */}
      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          {/* <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent post"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div> */}
        </Div>
      </Div>
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Our World wide partners and clients" />
      <Spacing lg="100" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="130" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Book your meeting now with ChainPeak"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
